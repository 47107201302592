import React, { useState, useEffect } from 'react';
import qaData from '../data/system_design_qa.json';

const Sidebar = ({ onSelectQuestion }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState(qaData);

  useEffect(() => {
    const filtered = qaData.filter(q => 
      q.question.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQuestions(filtered);
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="h-full bg-white shadow-lg overflow-y-auto flex flex-col">
      <div className="px-4 py-4 border-b border-gray-200">
        <input
          type="text"
          placeholder="Search questions..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto">
        {filteredQuestions.map(q => (
          <li 
            key={q.id} 
            className="px-4 py-3 hover:bg-gray-50 cursor-pointer"
            onClick={() => onSelectQuestion(q)}
          >
            {q.question}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;