import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import MainContent from './components/MainContent';
import Header from './components/Header';
import PracticePage from './components/PracticePage';
import QuestionPage from './components/QuestionPage';
import LoginPage from './components/LoginPage';
import PricingPage from './components/PricingPage';
import AccountPage from './components/AccountPage';
import { AuthProvider, AuthContext } from './AuthContext';
import Footer from './components/Footer';
import TermsPage from './components/TermsPage';
import PrivacyPage from './components/PrivacyPage';
import RefundPage from './components/RefundPage';
import ContactPage from './components/ContactPage';

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

function AppContent() {
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading component
  }

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Header />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/learn" element={<MainContent />} />
            <Route
              path="/practice"
              element={isAuthenticated ? <PracticePage /> : <Navigate to="/signin" />}
            />
            <Route path="/signin" element={<LoginPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route
              path="/question/:id"
              element={isAuthenticated ? <QuestionPage /> : <Navigate to="/signin" />}
            />
            <Route
              path="/settings"
              element={isAuthenticated ? <AccountPage /> : <Navigate to="/signin" />}
            />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/refund" element={<RefundPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;