import React, { useState, useEffect, useRef } from 'react';
import qaData from '../data/system_design_qa.json';

const SearchBar = ({ onSelectQuestion, isMobile }) => {
  
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (searchTerm.length > 2) {
      const filteredSuggestions = qaData
        .filter(item => item.question.toLowerCase().includes(searchTerm.toLowerCase()))
        .slice(0, 5); // Limit to 5 suggestions
      setSuggestions(filteredSuggestions);
      setIsDropdownVisible(true);
    } else {
      setSuggestions([]);
      setIsDropdownVisible(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSuggestionClick = (question) => {
    setSearchTerm(question.question);
    onSelectQuestion(question);
    setIsDropdownVisible(false);
  };

  return (
    <div className={`relative ${isMobile ? 'fixed inset-0 bg-white z-50 flex flex-col' : ''}`}>
      <input
        type="text"
        placeholder="Search questions..."
        value={searchTerm}
        onChange={handleInputChange}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      {isDropdownVisible && suggestions.length > 0 && (
        <ul ref={dropdownRef} className={`${isMobile ? 'flex-1 overflow-y-auto' : 'absolute z-10'} w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg`}>
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.id}
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.question}
            </li>
          ))}
        </ul>
      )}
      {isMobile && (
        <button
          className="p-4 bg-gray-200 text-center font-semibold"
          onClick={() => onSelectQuestion(null)}
        >
          Close
        </button>
      )}
    </div>
  );
};

export default SearchBar;