import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { AuthContext } from '../AuthContext';  // Import AuthContext

const PracticePage = () => {
  const [questions, setQuestions] = useState({ easy: [], medium: [], hard: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { isAuthenticated, isPro } = useContext(AuthContext);  // Use AuthContext

  useEffect(() => {
    const fetchQuestions = async () => {
      if (!isAuthenticated) {
        navigate('/signin');
        return;
      }

      setLoading(true);
      try {
        const response = await api.get('/questions');
        setQuestions(response.data);
      } catch (error) {
        console.error('Error:', error);
        setError('An error occurred. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [isAuthenticated, navigate]);

  useEffect(() => {
  }, [isPro, isAuthenticated]);

  const handleQuestionClick = (questionId, enabled) => {
    if (enabled || isPro) {
      navigate(`/question/${questionId}`);
    } else {
      navigate('/pricing');
    }
  };

  const difficultyColors = {
    easy: 'bg-green-100 text-green-800',
    medium: 'bg-yellow-100 text-yellow-800',
    hard: 'bg-red-100 text-red-800',
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredQuestions = Object.entries(questions)
    .flatMap(([difficulty, questionList]) =>
      questionList.map((q) => ({ ...q, difficulty }))
    )
    .filter((q) => 
      (filter === 'all' || q.difficulty === filter) &&
      q.content.toLowerCase().includes(searchTerm.toLowerCase())
    );

  if (loading) {
    return <div className="max-w-6xl mx-auto p-4">Loading...</div>;
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto p-4">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded" role="alert">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow max-w-6xl mx-auto p-4 w-full">
        <h1 className="text-3xl font-bold mb-8">Practice Questions</h1>
        <div className="mb-6 flex space-x-4">
          <div className="w-1/4">
            <select
              id="difficulty-filter"
              value={filter}
              onChange={handleFilterChange}
              className="border rounded p-2 w-full"
            >
              <option value="all">All</option>
              <option value="easy">Easy</option>
              <option value="medium">Medium</option>
              <option value="hard">Hard</option>
            </select>
          </div>
          <div className="w-3/4">
            <input
              type="text"
              placeholder="Search questions"
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full border rounded p-2"
            />
          </div>
        </div>
        <div className="border rounded">
          <table className="w-full table-fixed border-collapse">
            <thead>
              <tr className="bg-gray-100">
                <th className="border p-2 text-left w-16">Status</th>
                <th className="border p-2 text-center">Title</th>
                <th className="border p-2 text-left w-24">Difficulty</th>
              </tr>
            </thead>
            <tbody>
              {filteredQuestions.map((q) => (
                <tr key={q._id} className="hover:bg-gray-50">
                  <td className="border p-2 text-center">
                    {!q.enabled ? (
                      <span className="text-gray-500">🔒</span>
                    ) : q.passed_question ? (
                      <span className="text-green-500">✓</span>
                    ) : null}
                  </td>
                  <td className="border p-2">
                    <button
                      onClick={() => handleQuestionClick(q._id, q.enabled)}
                      className={`text-left w-full overflow-hidden text-ellipsis whitespace-nowrap ${
                        q.enabled ? 'text-blue-600 hover:underline' : 'text-gray-400 cursor-not-allowed'
                      }`}
                    >
                      {q.content}
                    </button>
                  </td>
                  <td className="border p-2">
                    <span className={`px-2 py-1 rounded text-sm font-semibold ${difficultyColors[q.difficulty]}`}>
                      {q.difficulty.charAt(0).toUpperCase() + q.difficulty.slice(1)}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PracticePage;
