import React from 'react';

const ContactPage = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <h1 className="text-3xl font-bold mb-6">Contact Us</h1>
      <p className="mb-6 text-lg">We'd love to hear from you! Here's how you can reach us:</p>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Address:</h2>
        <p className="whitespace-pre-line">
          SNN Raj Grandeur
          Bommanahalli, Bangalore - 560068
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">Email:</h2>
        <a href="mailto:shahi.shaurya@gmail.com" className="text-blue-600 hover:underline">
          shahi.shaurya@gmail.com
        </a>
      </div>

      <div>
        <h2 className="text-2xl font-semibold mb-2">Website:</h2>
        <a href="https://designinterviews.in" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
          designinterviews.in
        </a>
      </div>
    </div>
  );
};

export default ContactPage;
