import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-100 py-4">
      <div className="container mx-auto px-4 text-center">
        <nav className="mb-2">
          <Link to="/terms" className="text-blue-600 hover:text-blue-800 mx-2">Terms and Conditions</Link>
          <Link to="/privacy" className="text-blue-600 hover:text-blue-800 mx-2">Privacy Policy</Link>
          <Link to="/refund" className="text-blue-600 hover:text-blue-800 mx-2">Refund and Cancellation Policy</Link>
          <Link to="/contact" className="text-blue-600 hover:text-blue-800 mx-2">Contact Us</Link>
        </nav>
        <p className="text-sm text-gray-600">© 2024 designinterviews.in. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
