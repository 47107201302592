import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import api from '../api';
import ReactMarkdown from 'react-markdown';

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit' 
  });
};

const QuestionPage = () => {
  const { id } = useParams();
  const [question, setQuestion] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const { isAuthenticated, isPro } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [grade, setGrade] = useState(null);
  const [critique, setCritique] = useState('');
  const [showResults, setShowResults] = useState(false);
  const chatEndRef = useRef(null);
  const [inputHeight, setInputHeight] = useState('auto');
  const textareaRef = useRef(null);
  const [topSubmissions, setTopSubmissions] = useState([]);
  const [userSubmissions, setUserSubmissions] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [isGrading, setIsGrading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const fetchQuestion = async () => {
      if (!isAuthenticated) {
        navigate('/signin');
        return;
      }

      try {
        const response = await api.get(`/questions/${id}`);
        if (response.data.enabled || isPro) {
          setQuestion(response.data);
          setChatHistory(response.data.chat_history || []);
        } else {
          navigate('/pricing');
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          navigate('/pricing');
        }
      }
    };

    fetchQuestion();
  }, [id, navigate, isAuthenticated, isPro]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  const fetchTopSubmissions = useCallback(async () => {
    try {
      const response = await api.get(`/questions/${id}/top-submissions`);
      setTopSubmissions(response.data.top_submissions);
      setActiveTab('top');
    } catch (error) {
      console.error('Error fetching top submissions:', error);
    }
  }, [id]);

  const fetchUserSubmissions = useCallback(async () => {
    try {
      const response = await api.get(`/questions/${id}/user-submissions`);
      setUserSubmissions(response.data.user_submissions);
      setActiveTab('user');
    } catch (error) {
      console.error('Error fetching user submissions:', error);
    }
  }, [id]);

  const handleSendMessage = async () => {
    if (!userMessage.trim()) return;

    setIsLoading(true);
    setChatHistory([...chatHistory, { role: 'candidate', message: userMessage }]);
    setUserMessage('');
    setIsTyping(true);

    try {
      const response = await api.post(`/chat?message=${encodeURIComponent(userMessage)}&question_id=${id}`);
      setChatHistory(prevHistory => [
        ...prevHistory,
        { role: 'interviewer', message: response.data.message }
      ]);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
      setIsTyping(false);
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }
  };

  const handleSubmitAnswer = async () => {
    console.log('Submit for Grading button clicked'); // Log to verify function call
    setIsLoading(true);
    setIsGrading(true);
    setCritique('');
    setShowResults(true);
    setGrade(null);

    try {
      const token = localStorage.getItem('authToken'); // Ensure token is retrieved correctly
      if (!token) {
        throw new Error('No token found');
      }

      const response = await fetch(`${api.defaults.baseURL}/submit_answer?question_id=${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Ensure token is included in headers
        },
      });


      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const jsonData = line.slice(6).trim();
            if (jsonData === '[DONE]') break;

            try {
              const { grade, critique } = JSON.parse(jsonData);
              if (grade !== undefined) {
                setGrade(parseFloat(grade));
              }
              if (critique !== undefined) {
                setCritique(prev => prev + critique);
              }
            } catch (parseError) {
              console.error('Error parsing JSON:', parseError);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error submitting answer:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    } else if (e.key === 'Enter' && e.shiftKey) {
      setInputHeight('auto');
      setTimeout(() => {
        if (textareaRef.current) {
          setInputHeight(`${textareaRef.current.scrollHeight}px`);
        }
      }, 0);
    }
  };

  const handleInputChange = (e) => {
    setUserMessage(e.target.value);
    setInputHeight('auto');
    setTimeout(() => {
      if (textareaRef.current) {
        setInputHeight(`${textareaRef.current.scrollHeight}px`);
      }
    }, 0);
  };

  const handleBackToChat = () => {
    setActiveTab(null);
  };

  useEffect(() => {
    const focusTextarea = () => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    };

    const timeoutId = setTimeout(focusTextarea, 100);

    return () => clearTimeout(timeoutId);
  }, [chatHistory]);

  if (!question) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex justify-center space-x-4 p-4 bg-white border-b">
        {activeTab === null ? (
          <>
            <button 
              onClick={fetchTopSubmissions}
              className="px-4 py-2 rounded-full border transition duration-150 ease-in-out bg-white text-black hover:bg-gray-100"
            >
              ★ Top Submissions
            </button>
            <button 
              onClick={fetchUserSubmissions}
              className="px-4 py-2 rounded-full border transition duration-150 ease-in-out bg-white text-black hover:bg-gray-100"
            >
              👤 My Submissions
            </button>
          </>
        ) : (
          <button 
            onClick={handleBackToChat}
            className="px-4 py-2 rounded-full border transition duration-150 ease-in-out bg-white text-black hover:bg-gray-100"
          >
            ← Back to Chat
          </button>
        )}
      </div>

      <div className="flex-grow overflow-auto p-6 md:p-8 max-w-4xl mx-auto w-full">
        <h1 className="text-3xl font-bold mb-6">{question.content}</h1>
        
        {activeTab === 'top' && (
          <div className="space-y-6">
            <h2 className="text-2xl font-semibold mb-4">Top Submissions</h2>
            {topSubmissions.map((submission, index) => (
              <div key={index} className="bg-white p-4 rounded-lg shadow-sm">
                <div className="flex justify-between items-center mb-2">
                  <span className="font-semibold">{submission.user_name}</span>
                  <span className="text-sm text-gray-500">
                    {formatDate(submission.submitted_at)}
                  </span>
                </div>
                {submission.chat_history && submission.chat_history.length > 0 && (
                  <div className="mb-4">
                    {submission.chat_history.map((msg, msgIndex) => (
                      <div key={msgIndex} className={`p-2 rounded ${
                        msg.role === 'candidate' ? 'bg-blue-100' : 'bg-gray-100'
                      } mb-2`}>
                        <p className="font-semibold">{msg.role === 'candidate' ? 'Candidate' : 'Interviewer'}</p>
                        <p>{msg.message}</p>
                      </div>
                    ))}
                  </div>
                )}
                <div className="text-right">
                  {submission.grade !== undefined ? (
                    <span className={`font-bold ${submission.grade >= 8.0 ? 'text-green-500' : 'text-red-500'}`}>
                      Grade: {submission.grade.toFixed(1)}
                    </span>
                  ) : (
                    <span className="text-gray-500">Grade: N/A</span>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        {activeTab === 'user' && (
          <div className="space-y-6">
            <h2 className="text-2xl font-semibold mb-4">My Submissions</h2>
            {userSubmissions.map((submission, index) => (
              <div key={index} className="bg-white p-4 rounded-lg shadow-sm">
                <div className="flex justify-between items-center mb-2">
                  <span className="font-semibold">Your Submission</span>
                  <span className="text-sm text-gray-500">
                    {formatDate(submission.submitted_at)}
                  </span>
                </div>
                {submission.chat_history && submission.chat_history.length > 0 && (
                  <div className="mb-4">
                    {submission.chat_history.map((msg, msgIndex) => (
                      <div key={msgIndex} className={`p-2 rounded ${
                        msg.role === 'candidate' ? 'bg-blue-100' : 'bg-gray-100'
                      } mb-2`}>
                        <p className="font-semibold">{msg.role === 'candidate' ? 'You' : 'Interviewer'}</p>
                        <p>{msg.message}</p>
                      </div>
                    ))}
                  </div>
                )}
                <div className="text-right mb-2">
                  {submission.grade !== undefined ? (
                    <span className={`font-bold ${submission.grade >= 8.5 ? 'text-green-500' : 'text-red-500'}`}>
                      Grade: {submission.grade.toFixed(1)}
                    </span>
                  ) : (
                    <span className="text-gray-500">Grade: N/A</span>
                  )}
                </div>
                {submission.critique && (
                  <div className="mt-4">
                    <h3 className="text-lg font-semibold mb-2">Critique:</h3>
                    <div className="prose max-w-none">
                      <ReactMarkdown>{submission.critique}</ReactMarkdown>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {!isGrading && activeTab === null && (
          <div className="space-y-6">
            {chatHistory.map((msg, index) => (
              <div key={index} className={`p-4 rounded-lg shadow-sm ${
                msg.role === 'candidate' ? 'bg-blue-100 ml-auto max-w-[80%]' : 'bg-white max-w-[80%]'
              }`}>
                <p className="font-semibold mb-2">{msg.role === 'candidate' ? 'You' : 'Interviewer'}</p>
                <p className="text-gray-700">{msg.message}</p>
              </div>
            ))}
            {isTyping && (
              <div className="p-4 rounded-lg shadow-sm bg-white max-w-[80%]">
                <p className="font-semibold mb-2">Interviewer</p>
                <p className="text-gray-700">Interviewer is typing...</p>
              </div>
            )}
            <div ref={chatEndRef} />
          </div>
        )}

        {isGrading && (
          <div className="mt-10 bg-white p-6 rounded-lg shadow-sm">
            <h2 className={`text-4xl font-bold mb-4 ${grade !== null ? (grade >= 8.5 ? 'text-green-500' : 'text-red-500') : ''}`}>
              Grade: {grade !== null ? grade.toFixed(1) : 'Grading...'}
            </h2>
            <div className="prose max-w-none">
              <ReactMarkdown>{critique || 'Generating critique...'}</ReactMarkdown>
            </div>
          </div>
        )}
      </div>

      {!isGrading && activeTab === null && (
        <div className="p-4 md:p-6 bg-white border-t">
          <div className="max-w-4xl mx-auto">
            <div className="flex space-x-2 mb-3">
              <textarea
                ref={textareaRef}
                value={userMessage}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Type your message here..."
                className="flex-grow p-3 border rounded-lg resize-none overflow-hidden shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                style={{ height: inputHeight }}
                disabled={isLoading || showResults}
                autoFocus
              />
              <button
                onClick={handleSendMessage}
                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 self-end transition duration-150 ease-in-out"
                disabled={isLoading || showResults}
              >
                Send
              </button>
            </div>
            <button
              onClick={handleSubmitAnswer}
              className="w-full px-4 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-150 ease-in-out"
              disabled={isLoading || showResults}
            >
              Submit for Grading
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionPage;
