import React from 'react';

const PrivacyPage = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">Last updated: 6/09/2024</p>

      <p className="mb-4">At designinterviews.in, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and protect the personal information you provide to us.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">1. Information We Collect</h2>
      <p className="mb-2">We collect the following personal information when you use designinterviews.in:</p>
      <ul className="list-disc pl-8 mb-4">
        <li>Name</li>
        <li>Email address</li>
        <li>Usage data (e.g., interactions with the site, pages visited)</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2">2. How We Use Your Information</h2>
      <p className="mb-2">We use the collected data for the following purposes:</p>
      <ul className="list-disc pl-8 mb-4">
        <li>To analyze site usage and improve the user experience</li>
        <li>To provide personalized content based on your preferences</li>
        <li>For communication purposes, if necessary</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2">3. Data Access and Modification</h2>
      <p className="mb-4">You have the right to access, modify, and delete the personal information we hold about you. If you would like to request access, modification, or deletion of your data, please contact us at shahi.shaurya@gmail.com.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">4. Data Retention</h2>
      <p className="mb-4">We retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy or to comply with legal obligations.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">5. Security of Your Data</h2>
      <p className="mb-4">We take the protection of your personal data seriously. Appropriate security measures are in place to safeguard your information from unauthorized access, disclosure, alteration, or destruction.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">6. Children's Privacy</h2>
      <p className="mb-4">designinterviews.in does not knowingly collect personal information from individuals under the age of 18. If you are a parent or guardian and believe that your child has provided personal data to us, please contact us, and we will take steps to delete that information.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">7. Your Consent</h2>
      <p className="mb-4">By using designinterviews.in, you consent to the terms outlined in this Privacy Policy.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">8. Changes to This Policy</h2>
      <p className="mb-4">We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last updated" date will be revised accordingly.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">9. Contact Us</h2>
      <p className="mb-4">If you have any questions about this Privacy Policy, please contact us at shahi.shaurya@gmail.com.</p>
    </div>
  );
};

export default PrivacyPage;
