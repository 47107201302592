import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../AuthContext';

const Header = () => {
  const { isAuthenticated, user } = useContext(AuthContext);

  return (
    <header className="bg-white shadow-md">
      <nav className="container mx-auto px-6 py-3 flex justify-between items-center">
        <Link to="/" className="text-xl font-bold text-gray-800 hover:text-gray-600">
          Design Interviews
        </Link>
        <ul className="flex space-x-4">
          <li><Link to="/learn" className="text-gray-800 hover:text-gray-600">Learn</Link></li>
          <li><Link to="/practice" className="text-gray-800 hover:text-gray-600">Practice</Link></li>
          {/* {(!isAuthenticated || (isAuthenticated && !user?.is_pro)) && (
            <li><Link to="/pricing" className="text-gray-800 hover:text-gray-600">Pricing</Link></li>
          )} */}
          {isAuthenticated ? (
            <li><Link to="/settings" className="text-gray-800 hover:text-gray-600">Account</Link></li>
          ) : (
            <li><Link to="/signin" className="text-gray-800 hover:text-gray-600">Sign In</Link></li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;