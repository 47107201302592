import React, { useState, useEffect } from 'react';
import SearchBar from './SearchBar';

const MobileSearch = ({ questions, onSelectQuestion, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState(questions);

  useEffect(() => {
    const filtered = questions.filter(q => 
      q.question.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQuestions(filtered);
  }, [searchTerm, questions]);

  return (
    <div className="fixed inset-0 bg-white z-50 flex flex-col">
      <div className="p-4 border-b">
        <SearchBar onSearch={setSearchTerm} />
      </div>
      <div className="flex-1 overflow-y-auto">
        <ul className="divide-y divide-gray-200">
          {filteredQuestions.map(q => (
            <li 
              key={q.id} 
              className="p-4 hover:bg-gray-50 cursor-pointer"
              onClick={() => onSelectQuestion(q)}
            >
              {q.question}
            </li>
          ))}
        </ul>
      </div>
      <button
        className="p-4 bg-gray-200 text-center font-semibold"
        onClick={onClose}
      >
        Close
      </button>
    </div>
  );
};

export default MobileSearch;