import React, { useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';

const LoginPage = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleGoogleResponse = useCallback(async (response) => {
    if (response.credential) {
      try {
        const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8000'
        const res = await fetch(`${baseURL}/signin/google?token=${encodeURIComponent(response.credential)}`, {
          method: 'POST',
        });

        if (res.ok) {
          const data = await res.json();
          if (data.token) {
            console.log("We are logging in")
            login(data.token, data.user);
            setTimeout(() => navigate('/practice'), 0);
          } else {
            console.error('No token received from server');
          }
        } else {
          console.error('Failed to sign in:', await res.text());
        }
      } catch (error) {
        console.error('Error during sign-in:', error);
      }
    }
  }, [login, navigate]);

  const initializeGoogleSignIn = useCallback(() => {
    window.google.accounts.id.initialize({
      client_id: '875973414526-4gvaboifsa6gkkpajios11s8o6vju241.apps.googleusercontent.com',
      callback: handleGoogleResponse
    });
    window.google.accounts.id.renderButton(
      document.getElementById('googleSignInButton'),
      { theme: 'outline', size: 'large' }
    );
  }, [handleGoogleResponse]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = initializeGoogleSignIn;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [initializeGoogleSignIn]);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Sign In</h1>
        <div id="googleSignInButton"></div>
      </div>
    </div>
  );
};

export default LoginPage;
