import React from 'react';

const RefundPage = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <h1 className="text-3xl font-bold mb-4">Refund and Cancellation Policy</h1>
      <p className="mb-4">Last updated: 6/09/2024</p>

      <p className="mb-4">At designinterviews.in, we value your business and are committed to providing a high-quality service. This Refund and Cancellation Policy outlines the terms related to payments, refunds, and cancellations for our subscription-based services.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">1. Subscription Payments</h2>
      <p className="mb-4">All subscription payments made to designinterviews.in are final and non-refundable. By subscribing to our service, you agree to pay the recurring subscription fees as per the pricing plan selected.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">2. No Refund Policy</h2>
      <p className="mb-2">We do not offer any refunds for subscription payments once they are processed. This includes, but is not limited to:</p>
      <ul className="list-disc pl-8 mb-4">
        <li>Partial subscription periods</li>
        <li>Unused services or features</li>
        <li>Downgrade of plans</li>
      </ul>
      <p className="mb-4">Please ensure you review your subscription plan and features before making a purchase, as all sales are final.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">3. Cancellation Policy</h2>
      <p className="mb-4">You may cancel your subscription at any time; however, cancellations will only take effect at the end of the current billing cycle. Once you cancel, you will continue to have access to the service until the end of your current subscription period. After the billing cycle concludes, your subscription will not be renewed, and access to paid features will be terminated.</p>
      <p className="mb-4 font-semibold">Important: Cancellations do not trigger refunds for the remaining portion of your subscription period. You will still have full access to the service until the end of the current billing cycle.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">4. How to Cancel</h2>
      <p className="mb-4">To cancel your subscription, please log in to your account and follow the cancellation instructions provided in the account settings or contact us at shahi.shaurya@gmail.com for assistance.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">5. Changes to Pricing and Services</h2>
      <p className="mb-4">designinterviews.in reserves the right to change subscription prices or modify the features of any plan at any time. Any changes to your plan's pricing will take effect at the beginning of your next billing cycle. You will be notified in advance of any pricing changes.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">6. Contact Us</h2>
      <p className="mb-4">If you have any questions regarding this Refund and Cancellation Policy, please contact us at shahi.shaurya@gmail.com.</p>
    </div>
  );
};

export default RefundPage;
