import React from 'react';

const TermsPage = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
      <p className="mb-4">Last updated: 6/09/2024</p>

      <p className="mb-4">Welcome to designinterviews.in! By using our website, you agree to comply with and be bound by the following Terms and Conditions. Please review them carefully before using our site.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">1. Acceptance of Terms</h2>
      <p className="mb-4">By accessing and using designinterviews.in, you accept and agree to these Terms and Conditions. If you do not agree with these terms, please do not use our website.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">2. Use of the Website</h2>
      <p className="mb-4">You agree to use designinterviews.in for lawful purposes only and in a way that does not infringe the rights of others or restrict their use and enjoyment of the website. Prohibited activities include but are not limited to:</p>
      <ul className="list-disc pl-8 mb-4">
        <li>Submitting false or misleading information</li>
        <li>Engaging in conduct that is abusive, threatening, or harassing</li>
        <li>Attempting to gain unauthorized access to any part of the website</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-2">3. Intellectual Property</h2>
      <p className="mb-4">All content, including text, images, logos, and designs, on designinterviews.in is the property of designinterviews.in or its licensors and is protected by intellectual property laws. You may not reproduce, distribute, modify, or create derivative works without explicit permission from designinterviews.in.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">4. User Accounts</h2>
      <p className="mb-4">You may be required to create an account to access certain features on designinterviews.in. You agree to provide accurate information and keep your account credentials secure. You are responsible for all activities that occur under your account.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">5. Paid Features and Subscriptions</h2>
      <p className="mb-4">designinterviews.in offers certain features that are available for a fee. By purchasing a subscription or accessing paid content, you agree to our pricing and payment terms. All payments are non-refundable unless otherwise specified.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">6. Limitation of Liability</h2>
      <p className="mb-4">While we strive to provide accurate and up-to-date information, designinterviews.in makes no guarantees regarding the accuracy, reliability, or completeness of any content. designinterviews.in shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use or inability to use the website.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">7. User-Generated Content</h2>
      <p className="mb-4">If you submit any content (e.g., comments, feedback, or responses) to designinterviews.in, you grant us a non-exclusive, royalty-free, perpetual, and irrevocable right to use, modify, distribute, and display that content. You agree that the content you submit does not violate any laws or infringe on the rights of third parties.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">8. Termination</h2>
      <p className="mb-4">We reserve the right to terminate or suspend your access to designinterviews.in at any time, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users, us, or third parties.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">9. Changes to Terms and Conditions</h2>
      <p className="mb-4">We may update these Terms and Conditions from time to time. You are responsible for reviewing these terms regularly. Your continued use of the website following any changes constitutes your acceptance of those changes.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">10. Governing Law</h2>
      <p className="mb-4">These Terms and Conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising from these terms or your use of the website will be subject to the exclusive jurisdiction of the courts in [Your City], India.</p>

      <h2 className="text-2xl font-semibold mt-6 mb-2">11. Contact Us</h2>
      <p className="mb-4">If you have any questions about these Terms and Conditions, please contact us at shahi.shaurya@gmail.com.</p>
    </div>
  );
};

export default TermsPage;
