import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import api from '../api';

function PricingPage() {
  const navigate = useNavigate();
  const { isAuthenticated, user, login } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleContinue = async (plan) => {
    if (!isAuthenticated) {
      navigate('/signin');
      return;
    }
  
    if (plan === 'pro') {
      setIsLoading(true);
      try {
        // const planId = process.env.RAZORPAY_PLAN_ID || 'plan_OtayVun50wghGm';
        const planId = process.env.REACT_APP_RAZORPAY_PLAN_ID;
        const response = await api.post('/create-subscription', { plan_id: planId });
        const { subscription_id } = response.data;
        
        const options = {
          // key: process.env.RAZORPAY_KEY_ID || 'rzp_test_pgW6K10S8xbuOm',
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          subscription_id: subscription_id,
          name: 'Design Interviews',
          description: 'Pro Subscription',
          handler: function (response) {
            setIsLoading(false);
            verifySubscription(
              response.razorpay_payment_id,
              response.razorpay_subscription_id,
              response.razorpay_signature
            );
          },
          prefill: {
            email: user.email,
            contact: user.phone_number
          },
          theme: {
            color: '#3399cc'
          }
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
      } catch (error) {
        console.error('Error creating subscription:', error);
        setIsLoading(false);
      }
    } else {
      navigate('/');
    }
  };

  const verifySubscription = async (payment_id, subscription_id, signature) => {
    try {
      const response = await api.post('/verify-subscription', {
        razorpay_payment_id: payment_id,
        razorpay_subscription_id: subscription_id,
        razorpay_signature: signature
      });
      if (response.data.status === 'success') {
        // Update user context to reflect Pro status
        login(localStorage.getItem('authToken'), { ...user, is_pro: true });
        navigate('/practice');
      }
    } catch (error) {
      console.error('Error verifying subscription:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center mb-2">Choose Your Plan</h1>
      <p className="text-xl text-center text-gray-600 mb-8">Level up your skills with our tailored plans</p>
      <div className="flex flex-col md:flex-row justify-center items-stretch gap-8">
        <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between w-full md:w-80">
          <div>
            <h2 className="text-3xl font-bold mb-2">Free</h2>
            <p className="text-xl text-gray-600 mb-4">Get started</p>
            <p className="text-4xl font-bold mb-4">Rs. 0<span className="text-xl font-normal">/month</span></p>
            <ul className="mb-4">
              <li className="flex items-center mb-2">
                <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                Watch AI Architect vs AI Senior Engineer podcasts
              </li>
              <li className="flex items-center mb-2">
                <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                Access to "Learn" section
              </li>
              <li className="flex items-center mb-2">
                <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                Solve 2 easy problems for a Pro preview
              </li>
            </ul>
          </div>
          <button
            onClick={() => handleContinue('free')}
            className="bg-navy-blue text-white px-4 py-2 rounded-md hover:bg-blue-800 w-full"
          >
            Start Learning for Free
          </button>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between w-full md:w-80 border-2 border-blue-500 relative">
          <div className="absolute top-0 right-0 bg-blue-500 text-white px-2 py-1 text-sm rounded-bl">RECOMMENDED</div>
          <div>
            <h2 className="text-3xl font-bold mb-2">Pro</h2>
            <p className="text-xl text-gray-600 mb-4">Unlock full potential</p>
            <p className="text-4xl font-bold mb-4">Rs. 420<span className="text-xl font-normal">/month</span></p>
            <ul className="mb-4">
              <li className="flex items-center mb-2">
                <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                All Free features
              </li>
              <li className="flex items-center mb-2">
                <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                Use practice sessions
              </li>
              <li className="flex items-center mb-2">
                <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                Get your solution graded and critiqued by AI
              </li>
              <li className="flex items-center mb-2">
                <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                See Top Submissions
              </li>
            </ul>
          </div>
          <button
            onClick={() => handleContinue('pro')}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 w-full flex items-center justify-center"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing...
              </>
            ) : (
              'Upgrade to Pro'
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PricingPage;
