import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../AuthContext';

const LandingPage = () => {
  const { isAuthenticated, isPro } = useContext(AuthContext);

  const testimonials = [
    {
      name: "Shrey Sengar",
      role: "Senior Software Engineer at Microsoft",
      quote: "Design Interviews helped me ace my system design round. The practice problems are incredibly realistic!",
      stars: 5,
    },
    {
      name: "Mayuukh Varshney",
      role: "Lead Developer at Twilio",
      quote: "The instant feedback feature is a game-changer. It's like having a mentor available 24/7.",
      stars: 5,
    },
    {
      name: "Vishal Singh",
      role: "Senior Software Engineer at Microsoft",
      quote: "I've recommended Design Interviews to my entire team. It's an invaluable resource for improving system design skills.",
      stars: 5,
    },
    {
      name: "Suraj Poddar",
      role: "Senior Staff Engineer at Hevo Data",
      quote: "The variety of problems and detailed explanations have significantly boosted my confidence in interviews.",
      stars: 5,
    },
    {
      name: "Abhishek Pal",
      role: "Senior Software Engineer at Amazon",
      quote: "This platform has been crucial in my preparation for senior-level positions. The AI-powered feedback is spot-on!",
      stars: 5,
    },
    {
      name: "Gautam Sharan",
      role: "Senior Software Engineer at Collabera Technologies",
      quote: "The platform's comprehensive approach to system design has significantly improved my problem-solving skills. It's an essential tool for any engineer looking to grow.",
      stars: 5,
    }
  ];

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">

      <main className="flex-grow bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <h2 className="text-4xl sm:text-5xl font-bold text-center mb-8 text-gray-900">Crack System Design Interviews with Confidence</h2>
          <p className="text-xl text-gray-600 text-center mb-12">
            Level up your skills with real-world system design problems, solved and explained in detail in an interview format. Practice, get instant feedback and track your progress— all powered by AI to help you ace your next interview.
          </p>
          
          <div className="flex justify-center items-center space-x-8 mb-12">
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-2">
                <svg className="w-8 h-8 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" /></svg>
              </div>
              <span className="text-lg font-semibold">Learn</span>
            </div>
            <div className="text-gray-400">&rarr;</div>
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-2">
                <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" /></svg>
              </div>
              <span className="text-lg font-semibold">Practice</span>
            </div>
            <div className="text-gray-400">&rarr;</div>
            <div className="flex flex-col items-center">
              <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mb-2">
                <svg className="w-8 h-8 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" /></svg>
              </div>
              <span className="text-lg font-semibold">Compare</span>
            </div>
          </div>
          
          <div className="text-center">
            <Link 
              to="/practice" 
              className="inline-block bg-black text-white font-bold py-3 px-8 rounded-lg mb-4"
            >
              {isPro ? "Start Practicing" : "Start Practicing for Free"}
            </Link>
            {/* <br />
            {!isPro && (
              <Link to="/pricing" className="text-gray-600 hover:text-gray-900">
                Upgrade to Pro for $5/month
              </Link>
            )} */}
          </div>
        </div>
      </main>

      <footer className="bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <h3 className="text-2xl font-bold text-center mb-4">Listen to Our Podcast</h3>
          <div className="flex justify-center mb-12">
            <iframe
              title="Spotify Podcast Player"
              style={{ borderRadius: "12px" }}
              src="https://open.spotify.com/embed/show/3q322aCceMNYvYW7KmtMc6?utm_source=generator"
              width="100%"
              height="152"
              frameBorder="0"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
              className="max-w-xl w-full"
            ></iframe>
          </div>

          <div className="mt-16">
            <h3 className="text-3xl font-bold text-center mb-8">What Our Users Say</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {testimonials.map((testimonial, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex mb-2">
                    {[...Array(testimonial.stars)].map((_, i) => (
                      <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    ))}
                  </div>
                  <p className="text-gray-600 mb-4">"{testimonial.quote}"</p>
                  <p className="font-semibold">{testimonial.name}</p>
                  <p className="text-sm text-gray-500">{testimonial.role}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;