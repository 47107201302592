import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Sidebar from './Sidebar';
import MobileSearch from './MobileSearch';
import qaData from '../data/system_design_qa.json';

const MainContent = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatAnswer = (answer) => {
    return answer.trim().replace(/^\n+/, '');
  };

  const ParagraphRenderer = ({ children }) => (
    <p className="mb-4 whitespace-pre-wrap">{children}</p>
  );

  const handleSelectQuestion = (question) => {
    setSelectedQuestion(question);
    setShowMobileSearch(false);
  };

  const toggleMobileSearch = () => {
    setShowMobileSearch(!showMobileSearch);
  };

  return (
    <div className="flex flex-1 overflow-hidden">
      {!isMobile && (
        <div className="w-1/4 overflow-y-auto" style={{ height: 'calc(100vh - 64px)' }}>
          <Sidebar onSelectQuestion={handleSelectQuestion} />
        </div>
      )}
      <div className={`${isMobile ? 'w-full' : 'w-3/4'} overflow-y-auto`} style={{ height: 'calc(100vh - 64px)' }}>
        <div className="max-w-3xl mx-auto p-4 md:p-8">
          {isMobile && (
            <div className="mb-4">
              <button
                onClick={toggleMobileSearch}
                className="w-full px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
              >
                Search Questions
              </button>
            </div>
          )}
          {selectedQuestion ? (
            <div>
              <h2 className="text-xl md:text-2xl font-bold mb-4">
                {selectedQuestion.question}
              </h2>
              <div className="prose max-w-none text-sm md:text-base">
                <ReactMarkdown
                  components={{
                    p: ParagraphRenderer
                  }}
                >
                  {formatAnswer(selectedQuestion.answer)}
                </ReactMarkdown>
              </div>
              <div className="mt-8">
                <iframe
                  title="Spotify Podcast Player"
                  style={{ borderRadius: "12px" }}
                  src="https://open.spotify.com/embed/show/3q322aCceMNYvYW7KmtMc6?utm_source=generator"
                  width="100%"
                  height="152"
                  frameBorder="0"
                  allowFullScreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <p className="text-xl font-semibold mb-8">Select a system design question to get started!</p>
              <iframe
                title="Spotify Podcast Player"
                style={{ borderRadius: "12px" }}
                src="https://open.spotify.com/embed/show/3q322aCceMNYvYW7KmtMc6?utm_source=generator"
                width="100%"
                height="152"
                frameBorder="0"
                allowFullScreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </div>
          )}
        </div>
      </div>
      {isMobile && showMobileSearch && (
        <MobileSearch
          questions={qaData}
          onSelectQuestion={handleSelectQuestion}
          onClose={() => setShowMobileSearch(false)}
        />
      )}
    </div>
  );
};

export default MainContent;