import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import api from '../api'; // Make sure this import path is correct

const AccountPage = () => {
  const { logout, isPro, user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [cancelError, setCancelError] = useState(null);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await api.post('/cancel-subscription');
      
      if (response.data.status === 'cancelled') {
        if (typeof setUser === 'function') {
          setUser({ ...user, isPro: false });
        } else {
          console.warn('setUser is not a function. Unable to update user state.');
        }
        console.log(`${new Date(response.data.expiry_date).toLocaleDateString()}`)
        alert(`Subscription cancelled. Your Pro access will end on ${new Date(response.data.expiry_date).toLocaleDateString()}`);
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Subscription cancellation error:', error);
      setCancelError(error.response?.data?.detail || 'An error occurred while cancelling the subscription');
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-6">Account Settings</h1>
      <div>
        <h2 className="text-xl font-semibold mb-2">Subscription</h2>
        <p>Current plan: {isPro ? 'Pro' : 'Free'}</p>
        {isPro ? (
          <>
            <button
              onClick={handleCancelSubscription}
              className="mt-2 text-red-500 text-sm underline cursor-pointer"
            >
              Cancel Subscription
            </button>
            {cancelError && <p className="text-red-500 text-sm mt-1">{cancelError}</p>}
          </>
        ) : (
          <button
            onClick={() => navigate('/pricing')}
            className="mt-2 px-4 py-2 bg-green-500 text-white rounded text-sm"
          >
            Upgrade to Pro
          </button>
        )}
      </div>
      <div className="mt-6">
        <button
          onClick={handleLogout}
          className="px-4 py-2 bg-red-500 text-white rounded text-sm"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default AccountPage;
